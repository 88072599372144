import styled from '@emotion/styled'
import { Button, Chip, Text } from 'kai-kit'
import { useRouter } from 'next/router'
import { FC, useRef } from 'react'
import type { AriaButtonOptions } from 'react-aria'
import { useHover, useButton } from 'react-aria'
import { PiPaperPlaneTilt } from 'react-icons/pi'
import { Effects, SemanticColors } from '@/@types/theme'
import { FlexHorizontal } from '@/components/atom/Commons/FlexHorizontal'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { ImageContainer } from '@/components/atom/Images/ImageContainer'
import { Skelton } from '@/components/atom/Skelton'
import { CredType } from '@/components/templates/credential/AddCredItemContainer'
import { useVESSTheme } from '@/hooks/useVESSTheme'

type CredCardProps = {
  width?: string
  height?: string
  imgSrc?: string
  href?: string
  title?: string
  credType?: CredType
  holderNum?: number
  readonly?: boolean
  isHoverable?: boolean
  isLoading?: boolean
  tags?: string[]
  onPressCTA?: () => void
} & AriaButtonOptions<'div'>

export const CredCard: FC<CredCardProps> = ({
  width = '240px',
  height = 'fit-content',
  imgSrc = '',
  href = '',
  title = 'CredCardTitle',
  credType = 'default',
  holderNum = 0,
  readonly = false,
  isHoverable = true,
  isLoading = false,
  tags,
  onPressCTA,
  ...props
}) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const {
    buttonProps: { onClick, ...buttonProps },
  } = useButton({ onPress: () => router.push(href), ...props }, cardRef)
  const { hoverProps, isHovered } = useHover({ isDisabled: !isHoverable })
  const { currentTheme, currentTypo, currentEffects, getBasicFont, getText } = useVESSTheme()

  return (
    <Skelton
      isLoading={isLoading}
      width={width}
      height={height}
      variant={'outlined'}
      maskColor={currentTheme.background}
      radius={'24px'}
      borderWidth={'4px'}
    >
      <CardContainer
        onClick={readonly ? undefined : onClick}
        {...buttonProps}
        {...hoverProps}
        ref={cardRef}
        width={width}
        height={height}
        colorTheme={currentTheme}
        effects={currentEffects}
        data-readonly={readonly}
        data-hovered={isHovered}
      >
        <FlexVertical gap='16px' width='100%'>
          <ImageContainer width='100%' height='120px' src={imgSrc} objectFit='contain' />
          <FlexVertical gap='8px' width='100%' alignItems='center' justifyContent='center'>
            <Text as='h3' typo='title-lg' color={'var(--kai-color-sys-on-layer)'}>
              {title}
            </Text>
            <FlexHorizontal gap='8px' flexWrap='wrap'>
              <Chip size='sm' color='dominant' variant='tonal' round='sm'>
                {getText('Common', credType)}
              </Chip>
              {tags &&
                tags.length > 0 &&
                tags.map((tag) => (
                  <Chip key={tag} size='sm' color='dominant' variant='filled' round='sm'>
                    {tag}
                  </Chip>
                ))}
            </FlexHorizontal>
          </FlexVertical>
        </FlexVertical>
        <Button
          width='100%'
          onPress={onPressCTA}
          variant='tonal'
          color='dominant'
          startContent={<PiPaperPlaneTilt />}
        >
          発行する
        </Button>
      </CardContainer>
    </Skelton>
  )
}

const CardContainer = styled.div<{
  width: string
  height: string
  colorTheme: SemanticColors
  effects: Effects
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 32px;
  background: var(--kai-color-sys-layer-default);
  padding: var(--kai-size-sys-space-lg) var(--kai-size-sys-space-md) var(--kai-size-sys-space-md);
  display: flex;
  flex-direction: column;
  gap: var(--kai-size-sys-space-md);
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 var(--kai-size-ref-2) 0 var(--kai-color-sys-shadow);
  transition: all var(--kai-motion-sys-duration-fast) var(--kai-motion-sys-easing-standard);
  &[data-hovered='true'] {
    cursor: pointer;
    background: var(--kai-color-sys-layer-farther);
  }
  &[data-readonly='true'] {
    cursor: default;
    background: var(--kai-color-sys-layer-default);
  }
`
