import { Chip, Skelton, Text } from 'kai-kit'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { PiFunnelBold } from 'react-icons/pi'
import { CredType, CredTypeUnion } from './AddCredItemContainer'
import { FlexHorizontal } from '@/components/atom/Commons/FlexHorizontal'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { CardListLayout } from '@/components/atom/layouts/CardListLayout'
import { HCLayout } from '@/components/atom/layouts/HCLayout'
import { CollectionCard } from '@/components/molecule/Credential/CollectionCard'
import { CredCard } from '@/components/molecule/Credential/CredCard'
import { BasicHeader } from '@/components/organism/Headers/BasicHeader'
import { useVESSTheme } from '@/hooks/useVESSTheme'
import type { GetCollectionResponse, VSCredentialItemFromBuckup } from '@/lib/kms'

export type CredentialListViewProps = {
  isChildCollection?: boolean
  collectionName?: string
  collections?: GetCollectionResponse[]
  credentials?: VSCredentialItemFromBuckup[]
  isLoading?: boolean
}

export const CredentialListView: FC<CredentialListViewProps> = ({
  isChildCollection,
  collectionName,
  collections,
  credentials,
  isLoading,
}) => {
  const { getText } = useVESSTheme()
  const [selectedType, setSelectedType] = useState('all')
  const router = useRouter()

  return (
    <HCLayout
      header={
        <BasicHeader
          title={collectionName || getText('Common', 'Credential')}
          showBackButton={!!isChildCollection}
          onClickBackButton={() => router.back()}
        ></BasicHeader>
      }
    >
      <FlexVertical
        padding='var(--kai-size-sys-space-md) var(--kai-size-ref-32)'
        gap={'var(--kai-size-sys-space-lg)'}
        width={'100%'}
      >
        <FlexVertical gap='16px'>
          {!isChildCollection && (
            <FlexHorizontal gap={'8px'}>
              <PiFunnelBold size={20} color='var(--kai-color-sys-on-layer-minor)' />
              <Chip
                size='md'
                color='dominant'
                variant={selectedType == 'all' ? 'tonal' : 'outlined'}
                onPress={() => setSelectedType('all')}
              >
                全て
              </Chip>
              {CredTypeUnion.slice(0, -1).map((type) => {
                return (
                  <Chip
                    key={type}
                    size='md'
                    color='dominant'
                    variant={selectedType == type ? 'tonal' : 'outlined'}
                    onPress={() => setSelectedType(type)}
                  >
                    {getText('Common', type)}
                  </Chip>
                )
              })}
            </FlexHorizontal>
          )}
        </FlexVertical>
        {collections?.length != 0 && (
          <FlexVertical gap='var(--kai-size-sys-space-sm)' width='100%'>
            <Text as='h4' typo='label-lg' color={'var(--kai-color-sys-on-layer-minor'}>
              コレクション
            </Text>
            <CardListLayout width='100%' gap='var(--kai-size-sys-space-md)'>
              {collections &&
                collections?.length > 0 &&
                collections?.map((collection) => {
                  return (
                    <CollectionCard
                      key={collection.id}
                      title={collection.name}
                      width='100%'
                      onPress={() => {
                        router.push(`/credential/c/${collection.id}`)
                      }}
                    />
                  )
                })}
            </CardListLayout>
          </FlexVertical>
        )}
        <FlexVertical gap='var(--kai-size-sys-space-sm)' width='100%'>
          <Text as='h4' typo='label-lg' color={'var(--kai-color-sys-on-layer-minor'}>
            クレデンシャル
          </Text>
          <CardListLayout width='100%'>
            {isLoading ? (
              <>
                <Skelton
                  isLoading
                  width={'100%'}
                  height={'320px'}
                  radius={'24px'}
                  borderWidth={'4px'}
                />
                <Skelton
                  isLoading
                  width={'100%'}
                  height={'320px'}
                  radius={'24px'}
                  borderWidth={'4px'}
                />
                <Skelton
                  isLoading
                  width={'100%'}
                  height={'320px'}
                  radius={'24px'}
                  borderWidth={'4px'}
                />
              </>
            ) : (
              <>
                {credentials?.length !== 0 ? (
                  credentials?.map((item) => {
                    return (
                      <>
                        {(item.credentialType.name == selectedType || selectedType == 'all') && (
                          <CredCard
                            key={item.id}
                            width='100%'
                            height='320px'
                            title={item?.title}
                            imgSrc={item?.image || item?.icon || ''}
                            credType={(item.credentialType.name as CredType) || 'default'}
                            href={`/credential/detail/${item?.id}/`}
                            onPressCTA={() =>
                              router.push(`/credential/detail/distribute/${item?.id}`)
                            }
                            isLoading={isLoading}
                            tags={item?.Tagged?.map((tag) => tag.tag?.name || '')}
                          />
                        )}
                      </>
                    )
                  })
                ) : (
                  <>
                    <Text as='p' typo='title-sm' color='var(--kai-color-sys-on-dominant-backing)'>
                      クレデンシャルはありません。
                    </Text>
                  </>
                )}
              </>
            )}
          </CardListLayout>
        </FlexVertical>
      </FlexVertical>
    </HCLayout>
  )
}
