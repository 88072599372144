import styled from '@emotion/styled'
import { Chip, Text } from 'kai-kit'
import { FC } from 'react'
import { Button as RACButton } from 'react-aria-components'
import type { ButtonProps as RACButtonProps } from 'react-aria-components'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { CredType } from '@/components/templates/credential/AddCredItemContainer'
import { useVESSTheme } from '@/hooks/useVESSTheme'

export type CollectionCardProps = RACButtonProps & {
  title: string
  credType?: CredType
  width?: string
}

export const CollectionCard: FC<CollectionCardProps> = ({
  title,
  credType,
  width = 'fit-content',
  ...props
}: CollectionCardProps) => {
  const { getText } = useVESSTheme()
  return (
    <StyledButton width={width} {...props}>
      <FlexVertical
        width='100%'
        gap={'var(--kai-size-sys-space-2xs)'}
        padding='0 var(--kai-size-sys-space-xs) 0 0'
      >
        <Text
          as='p'
          typo='title-md'
          color='var(--kai-color-sys-on-layer)'
          width='100%'
          lineClamp={1}
        >
          {title}
        </Text>
        {credType && (
          <Chip variant='tonal' size='sm' round='sm'>
            {getText('Common', credType)}
          </Chip>
        )}
      </FlexVertical>
      {/* <Menu
        placement='right top'
        width='var(--kai-size-ref-160)'
        triggerButton={
          <IconButton
            icon={<PiDotsThreeVerticalBold />}
            color='neutral'
            variant='text'
            size='sm'
            aria-label='メニューを開く'
          />
        }
      >
        <MenuItem>名前を変更</MenuItem>
      </Menu> */}
    </StyledButton>
  )
}

const StyledButton = styled(RACButton)<{ width?: string }>`
  width: ${({ width }) => width};
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--kai-size-sys-space-md);
  padding-right: var(--kai-size-sys-space-xs);
  background: var(--kai-color-sys-layer-default);
  border-radius: var(--kai-size-sys-round-md);
  border: none;
  box-shadow: 0 var(--kai-size-ref-1) 0 var(--kai-color-sys-shadow);
  transition: var(--kai-motion-sys-duration-fast) var(--kai-motion-sys-easing-standard);
  transition-property: transform, opacity, background;
  &[data-hovered] {
    cursor: pointer;
    background: var(--kai-color-sys-layer-farther);
  }
  &[data-pressed] {
    transform: scale(0.98);
    opacity: 0.8;
  }
  &[data-disabled] {
    opacity: 0.4;
    cursor: default;
  }
  &[data-focused] {
    outline: none;
  }
  &[data-focus-visible] {
    outline: var(--kai-size-ref-2) solid var(--kai-color-sys-dominant);
    outline-offset: var(--kai-size-ref-2);
  }
`
